import Vue from 'vue'

// axios
import axios from 'axios'

// const obj = {
//   // site_admin: window.location.origin,
//   site_admin: 'https://admin.power100th.com',
// }
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
   baseURL: 'https://api.cybercheck-ccib.com/api',
  // baseURL: 'https://cybercheck.cyberpolice.go.th/api',
  // baseURL: 'https://adminapi.hypervps.co/api',
  //  baseURL: 'http://127.0.0.1:3333/api',
  // baseURL: 'http://127.0.0.1:3201/api',
  // baseURL: endpoint,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})
// axiosIns.interceptors.request.use(
//   async config => {
//     await axios.post('https://adminapi.pp.com/api/agent/showid', obj)
//     // eslint-disable-next-line no-unused-vars
//       .then(response => {
//         localStorage.setItem('endpoint', response.data.endpoint)
//         // eslint-disable-next-line no-param-reassign
//         config.baseURL = response.data.endpoint
//       })
//       .catch(error => console.log(error))
//     // console.log(config.headers.Authorization)

//     return config
//   },
//   error => Promise.reject(error),
// )
Vue.prototype.$http = axiosIns

export default axiosIns

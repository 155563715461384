<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-cloud-download font-scale="3" animation="cylon" />
        <p id="cancel-label">กรุณารอสักครู่...</p>
      </div>
    </template>
    <b-card no-body>
      <b-card-body>
        <div class="d-md-flex justify-content-between align-items-start">
          <div class="px-1 pt-1 flex w-100">
            <b-form-group>
              <div class="row">
                <!-- File Upload Section -->
                <div class="col-md-4 mb-1">
                  <b-form-file
                    v-model="selectedFile"
                    :state="Boolean(selectedFile)"
                    placeholder="กรุณาเลือกไฟล์ excel"
                    drop-placeholder="กรุณาเลือกไฟล์ excel..."
                    @change="handleFileChange"
                  />
                </div>
                <!-- Export Button Section -->
                <div class="col-md-4 w-100 mb-1">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    class="rounded"
                    variant="gradient-success"
                    @click="importData"
                    block
                  >
                    อัพโหลดไฟล์ Excel
                  </b-button>
                </div>
                <div class="col-md-4 w-100">
                  <b-button
                    style="box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1)"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    class="rounded"
                    variant="gradient-success"
                    block
                    @click="exportData()"
                  >
                    Export CSV
                  </b-button>
                </div>
              </div>
            </b-form-group>
          </div>

          <div class="px-1 pt-1 col-md-6">
            <b-form-group class="tel ml-auto">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="gradient-primary"
                @click="showModal"
                block
              >
                เพิ่มข้อมูลเบอร์โทรศัพท์
              </b-button>
            </b-form-group>
          </div>
        </div>

        <div class="d-md-flex justify-content-between align-items-start">
          <div class="px-1 flex w-100">
            <b-form-group>
              <div class="row">
                <div class="col-md-6">
                  <b-form-group label="วันที่เริ่มต้น">
                    <b-form-datepicker
                      v-model="dateStart"
                      placeholder="เลือกวันที่เริ่มต้น"
                      class="date-input "
                      :locale="locale"
                      v-bind="labels[locale] || {}"
                    />
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group label="วันที่สิ้นสุด">
                    <b-form-datepicker
                      v-model="dateEnd"
                      placeholder="เลือกวันที่สิ้นสุด"
                      class="date-input "
                      :locale="locale"
                      v-bind="labels[locale] || {}"
                    />
                  </b-form-group>
                </div>
              </div>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <div class="row align-items-center">
              <div class="col-md-3">
                <b-form-group label="สถานะ">
                  <b-form-select
                    placeholder=""
                    v-model="search_type"
                    class="form-control-select"
                    :options="StatusOptions"
                    :value="null"
                  />
                </b-form-group>
              </div>

              <div class="col-md-3">
                <b-form-group label="ข้อมูล">
                  <b-form-select
                    placeholder=" "
                    v-model="search_data"
                    class="form-control-select"
                    :options="TypeOptions"
                    :value="null"
                  />
                </b-form-group>
              </div>

              <div class="col-md-6 mt-1">
                <b-input-group>
                  <b-form-input
                    v-model="search_val"
                    placeholder="กรอกข้อมูลที่ต้องการค้นหา"
                    @keyup.enter="getUsers()"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="info"
                      @click="getUsers()"
                      class="rounded-right"
                    >
                      <i class="fal fa-search" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>
            </div>
          </div>
        </div>
      </b-card-body>

      <b-table
        striped
        hover
        responsive
        show-empty
        class="position-relative items-center"
        :per-page="perPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #empty="scope">
          <div class="p-2 text-center">
            <img src="/box.png" alt="empty" height="40px" />
            {{
              scope.emptyFiltered ? "No matching records found" : "ไม่พบข้อมูล"
            }}
          </div>
        </template>
        <template #cell(level)="data">
          <b-button
            pill
            :variant="rowClass(data.item).color"
            class="text-nowrap"
          >
            {{ rowClass(data.item).text }}
          </b-button>
        </template>

        <template #cell(actions)="data">
          <b-button
            v-b-tooltip.hover.v-info
            title="แก้ไข"
            variant="gradient-info"
            size="sm"
            class="mr-1"
            @click="editModal(data.item)"
          >
            <i class="fas fa-pencil" />
          </b-button>
          <b-button
            v-b-tooltip.hover.v-info
            title="ลบ"
            variant="gradient-danger"
            size="sm"
            @click="remove_data(data.item)"
          >
            <i class="fas fa-trash-alt" />
          </b-button>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="getUsers()"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="getUsers()"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
    <b-modal
      ref="my-modal"
      hide-footer
      title="เพิ่มข้อมูลเบอร์โทรศัพท์"
      @hidden="ClearForm()"
    >
      <b-form @submit.prevent="addPhone()">
        <b-row>
          <!-- first name -->
          <b-col cols="12">
            <b-form-group label="ชื่อเรียก" label-for="vi-first-name">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="UserIcon" />
                </b-input-group-prepend>
                <b-form-input id="vi-first-name" v-model="fullName" />
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- Mobile -->
          <b-col cols="12">
            <b-form-group label="เบอร์โทร" label-for="vi-mobile">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SmartphoneIcon" />
                </b-input-group-prepend>
                <b-form-input id="vi-mobile" v-model="mobile" type="number" />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="สถานะ" label-for="vi-level">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    icon="BookmarkIcon"
                    :style="`color: ${
                      level == 0
                        ? 'green'
                        : level == 1
                        ? 'orange'
                        : level == 2
                        ? 'red'
                        : 'black'
                    };`"
                  />
                </b-input-group-prepend>
                <b-form-select
                  id="vi-level"
                  v-model="level"
                  :options="level_option"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="หมายเหตุ" label-for="vi-remark">
              <b-form-textarea id="vi-remark" v-model="remark" rows="3" />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              block
            >
              ยืนยัน
            </b-button>
          </b-col>

          <b-col cols="6">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              block
              @click="ClearForm(), hideModal()"
            >
              ยกเลิก
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>

    <b-modal
      ref="my-modal2"
      hide-footer
      title="แก้ไขข้อมูลเบอร์โทรศัพท์"
      @hidden="ClearForm()"
    >
      <b-form @submit.prevent="editPhone(edit_id)">
        <b-row>
          <!-- first name -->
          <b-col cols="12">
            <b-form-group label="Full Name" label-for="vi-first-name">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="UserIcon" />
                </b-input-group-prepend>
                <b-form-input id="vi-first-name" v-model="fullName" />
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- Mobile -->
          <b-col cols="12">
            <b-form-group label="Mobile" label-for="vi-mobile">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SmartphoneIcon" />
                </b-input-group-prepend>
                <b-form-input id="vi-mobile" v-model="mobile" type="number" />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="สถานะ" label-for="vi-level">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    icon="BookmarkIcon"
                    :style="`color: ${
                      level == 0
                        ? 'green'
                        : level == 1
                        ? 'orange'
                        : level == 2
                        ? 'red'
                        : 'black'
                    };`"
                  />
                </b-input-group-prepend>
                <b-form-select
                  id="vi-level"
                  v-model="level"
                  :options="level_option"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="remark" label-for="vi-remark">
              <b-form-textarea id="vi-remark" v-model="remark" rows="3" />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              block
            >
              ยืนยัน
            </b-button>
          </b-col>

          <b-col cols="6">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              block
              @click="$refs['my-modal2'].hide()"
            >
              ยกเลิก
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BInputGroupPrepend,
  BModal,
  BCard,
  BTable,
  BAvatar,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BButton,
  BCardBody,
  VBToggle,
  BOverlay,
  BIconCloudDownload,
  VBTooltip,
  BFormDatepicker,
  // BFormCheckbox,
  BFormTextarea,
  BForm,
  BFormFile,
  BInputGroupAppend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import moment from "moment-timezone";
// import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BRow,
    BCol,
    BInputGroupPrepend,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormTextarea,
    BButton,
    BCardBody,
    BOverlay,
    BForm,
    BIconCloudDownload,
    BFormFile,
    BFormDatepicker,
    // BFormCheckbox,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      level: 1,
      level_option: [],
      fullName: "",
      mobile: "",
      remark: "",
      search_type_option: [
        { value: 1, text: "ยูสเซอร์เนม" },
        { value: 2, text: "เบอร์โทร" },
        { value: 3, text: "ชื่อลูกค้า" },
        { value: 4, text: "เลขบัญชี" },
      ],
      search_data: "tel",
      search_type: null,
      search_val: "",
      show: true,
      prolist: [],
      pro_id: null,
      depositdata: [],
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      banklist: [],
      update_id: null,
      edit_id: null,
      edit_password: null,
      edit_name: null,
      edit_surname: null,
      edit_bank: null,
      edit_acc_no: null,
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      StatusOptions: [
        { value: null, text: "ทั้งหมด" },
        { value: 1, text: "มีประวัติการแจ้งเบาะแส" },
        { value: 2, text: "มีประวัติการแจ้งความดำเนินคดี" },
        { value: 3, text: "ได้รับการยืนยันตัวตนแล้ว" },
      ],
      TypeOptions: [
        { value: "tel", text: "เบอร์โทร" },
        { value: "name", text: "ชื่อเรียก" },
        { value: "remark", text: "หมายเหตุ" },
      ],
      fields: [
        { key: "index", label: "ลำดับ" },
        { key: "level", label: "สถานะ" },
        { key: "tel", label: "เบอร์โทร" },
        { key: "name", label: "ชื่อเรียก" },
        { key: "remark", label: "หมายเหตุ" },
        // { key: 'remark', label: 'remark' },
        {
          key: "created_at",
          label: "ลงทะเบียนเมื่อ",
          formatter: (value) =>
            value
              ? moment(value).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm:ss")
              : "",
        },
        { key: "actions", label: "จัดการ" },
      ],
      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      UserData: JSON.parse(localStorage.getItem("userData")),
      dateStart: moment().tz("Asia/Bangkok").format("YYYY-MM-DD"),
      dateEnd: moment().tz("Asia/Bangkok").format("YYYY-MM-DD"),
      selectedFile: null,
      locale: "th",
      labels: {
        th: {
          labelPrevDecade: "ทศวรรษก่อนหน้า",
          labelPrevYear: "ปีก่อนหน้า",
          labelPrevMonth: "เดือนก่อนหน้า",
          labelCurrentMonth: "เดือนปัจจุบัน",
          labelNextMonth: "เดือนถัดไป",
          labelNextYear: "ปีถัดไป",
          labelNextDecade: "ทศวรรษถัดไป",
          labelToday: "วันนี้",
          labelSelected: "วันที่ที่เลือก",
          labelNoDateSelected: "ยังไม่ได้เลือกวันที่",
          labelCalendar: "ปฏิทิน",
          labelNav: "การนำทางปฏิทิน",
          labelHelp: "ใช้ปุ่มลูกศรเพื่อเลือกวันที่",
        },
      },
      locales: [{ value: "th", text: "Thai (th)" }],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  created() {
    this.interval = setInterval(() => {
      this.getUsers();
      // this.Get_promotionlist()
    }, 30000);
  },
  destroyed() {
    clearInterval(this.interval);
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    // this.Get_userlist()
    this.getLevel();
    this.getUsers();

    // this.Get_promotionlist()
    // this.Get_bank()
  },
  methods: {
    remove_data(data) {
      this.$swal({
        title: '<h3 style="color: #141414">กรุณายืนยันในการลบข้อมูล</h3>',
        icon: "warning",
        showCancelButton: true,
        text: `ต้องการลบข้อมูลเบอร์โทร ${data.tel} หรือไม่`,
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ยืนยัน",
        customClass: {
          confirmButton: "btn btn-info",
          cancelButton: "btn btn-secondary ml-1",
        },
      }).then((result) => {
        if (result.value) {
          this.show = true;
          const obj = {
            // eslint-disable-next-line no-underscore-dangle
            ID: data._id,
          };
          this.$http
            .post("/tels/remove", obj)
            .then((response) => {
              this.getUsers();
              this.$swal({
                icon: "success",
                title: '<h3 style="color: #141414">สำเร็จ</h3>',
                html: '<p style="color: #141414">ลบข้อมูลเรียบร้อย</p>',
                showConfirmButton: false,
                timer: 2000,
              });
              this.show = false;
            })
            .catch((err) => {
              console.log(err);
              this.show = false;
            });
        }
      });
    },
    handleFileChange(event) {
      // eslint-disable-next-line prefer-destructuring
      this.selectedFile = event.target.files[0];
    },
    importData() {
      if (this.selectedFile) {
        this.show = true;
        const formData = new FormData();
        formData.append("csv", this.selectedFile);

        // เรียกใช้ฟังก์ชันส่งข้อมูลโดยใช้ Axios
        this.$http
          .post("/ImportCsv", formData)
          .then((response) => {
            this.getUsers();
            this.Success(response.data.message);
            this.selectedFile = null;
            this.show = false;
          })
          .catch((error) => {
            this.show = false;
            this.SwalError(error.response.data.message);
          });
      } else {
        console.error("No file selected");
      }
    },

    async exportData() {
      this.show = true;
      const params = {
        search_val: this.search_val,
        search_type: this.search_type,
        search_data: this.search_data,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      };
      try {
        const response = await this.$http.get("/call/ExportTel", {
          params,
        });
        this.show = false;
        const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
        const csvData = new Blob([bom, response.data], {
          type: "text/csv;charset=utf-8;",
        });
        const now = new Date();
        const dateString = now.toISOString().slice(0, 10); // YYYY-MM-DD
        const timeString = now.toTimeString().split(" ")[0].replace(/:/g, "-"); // HH-MM-SS
        const fileName = `DataPhone_${dateString}_${timeString}.csv`;

        const link = document.createElement("a");
        const url = window.URL.createObjectURL(csvData);
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.log(error);
        this.show = false;
      }
    },

    async getLevel() {
      try {
        const { data: res } = await this.$http.get("/call/showlevel");
        this.level_option = res;
      } catch (error) {
        console.log(error);
      }
    },
    rowClass(item) {
      const FindLevel = this.level_option.find((x) => x.value === item.level);
      if (FindLevel) {
        return {
          color: FindLevel.color,
          text: FindLevel.text,
        };
      }
      return {
        color: "secondary",
        text: "-",
      };
    },
    addPhone() {
      // const i = this.level_option.find(x => x.value === this.level)
      const params = {
        name: this.fullName,
        tel: this.mobile,
        remark: this.remark,
        level: this.level,
        levelname: this.level_option[this.level - 1].text,
      };
      this.$http
        .post("/numbers/store", params)
        .then(() => {
          this.getUsers();
          this.Success("เพิ่มข้อมูลสำเร็จ");
          this.ClearForm();
          this.hideModal();
        })
        .catch((error) => {
          this.SwalError(error.response.data.message);
        });
    },
    editPhone(id) {
      // const i = this.level_option.find(x => x.value === this.level)
      // console.log(i)
      const params = {
        name: this.fullName,
        tel: this.mobile,
        remark: this.remark,
        level: this.level,
        levelname: this.level_option[this.level - 1].text,
      };
      this.$http
        .post(`/numbers/update/${id}`, params)
        .then(() => {
          this.getUsers();
          this.Success("แก้ไขข้อมูลสำเร็จ");
          this.ClearForm();
          this.$refs["my-modal2"].hide();
        })
        .catch((error) => {
          this.SwalError(error.response.data.message);
        });
    },
    ClearForm() {
      this.fullName = "";
      this.mobile = "";
      this.remark = "";
      this.level = 0;
    },
    editModal(val) {
      this.$refs["my-modal2"].show();
      this.fullName = val.name;
      this.mobile = val.tel;
      this.remark = val.remark;
      this.level = val.level;
      // eslint-disable-next-line no-underscore-dangle
      this.edit_id = val._id;
    },
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    Export() {
      window.open(
        "/export"
        // '/export',
      );
    },
    addpro(id) {
      this.update_id = id;
      // console.log(id)
    },
    editmember(data) {
      this.edit_id = data.id;
      this.edit_name = data.name;
      this.edit_surname = data.surname;
      this.edit_bank = data.bank;
      this.edit_acc_no = data.acc_no;
      // console.log(data)
    },
    update_member() {
      const params = {
        name: this.edit_name,
        password: this.edit_password,
        surname: this.edit_surname,
        bank: this.edit_bank,
        acc_no: this.edit_acc_no,
      };
      // console.log(params)
      this.$http
        .post(`/users/update_namepass/${this.edit_id}`, params)
        .then((response) => {
          console.log(response);
          this.show = false;
          this.getUsers();
          this.edit_name = "";
          this.edit_password = "";
          this.edit_surname = "";
          this.edit_bank = "";
          this.edit_acc_no = "";
        })
        .catch((error) => console.log(error));
    },
    Get_bank() {
      this.$http
        .get("/bank/list")
        .then((response) => {
          this.banklist = response.data;
        })
        .catch((error) => console.log(error));
    },
    Submit_promotion() {
      const params = {
        main_promotion: this.pro_id,
      };
      this.$http
        .post(`/users/update/${this.update_id}`, { params })
        .then(() => {
          this.getUsers();
          this.Success("อัพเดทโปรโมชั่น สำเร็จ");
          this.update_id = null;
          this.pro_id = null;
        })
        .catch((error) => {
          this.SwalError(error.response.data.message);
        });
    },
    Get_promotionlist() {
      this.$http
        .get("/promotion/list")
        .then((response) => {
          this.prolist = response.data;
        })
        .catch((error) => console.log(error));
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    async getUsers() {
      this.show = true;
      // const index = 0
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        search_val: this.search_val,
        search_type: this.search_type,
        search_data: this.search_data,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      };
      await this.$http
        .post("/tels/index", params)
        .then((response) => {
          this.show = false;
          // this.depositdata = response.data.data
          // this.depositdata.forEach(items => {
          //   this.getBalance(items.username, index)
          //   index += 1
          // })
          this.onFiltered(response.data);
        })
        .catch((error) => console.log(error));
    },
    getBalance(username, index) {
      const params = {
        username,
      };
      this.$http
        .get("/balance/getbalance", { params })
        .then((response) => {
          this.show = false;
          // console.log(response.data)
          this.depositdata[index].updated_at = response.data.balance;
        })
        .catch((error) => console.log(error));
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems.data;
      this.totalRows = filteredItems.total;
      // this.currentPage = 1
    },
    Get_userlist() {
      this.$http
        .get("/api/user/list")
        .then((response) => {
          this.onFiltered(response.data.message);
        })
        .catch((error) => console.log(error));
    },
    addData() {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true);
      this.datasidebar = {};
    },
    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true);
      this.datasidebar = data;
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    },
    Success(mes) {
      this.$swal({
        icon: "success",
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    },
    SwalError(mes) {
      this.$swal({
        icon: "error",
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.form-item-section {
  background-color: $product-details-bg;
}
@media (min-width: 992px) {
  .tel {
    width: 50% !important; /* ใช้ width 50% สำหรับหน้าจอใหญ่ */
  }
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

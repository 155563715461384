var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_vm._m(0),_c('validation-observer',{ref:"formRules"},[_c('div',{staticClass:"row custom-card"},[_c('div',{staticClass:"col-lg-6",staticStyle:{"z-index":"1"}},[_c('div',{staticClass:"card hovered"},[_c('h3',[_vm._v("ข้อมูลผู้แจ้ง")]),_c('form',[_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"firstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"firstName"}},[_vm._v("ชื่อ")]),_c('b-form-input',{staticClass:"form-control",attrs:{"id":"firstName","type":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "กรุณากรอกชื่อ" : ""))])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"lastName"}},[_vm._v("นามสกุล")]),_c('b-form-input',{staticClass:"form-control",attrs:{"id":"lastName","type":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "กรุณากรอกนามสกุล" : ""))])]}}])})],1)]),_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-md-6"},[_c('label',[_vm._v("จังหวัด")]),_c('validation-provider',{attrs:{"name":"province_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"province_name","options":_vm.province_Info,"reduce":function (name) { return name.province_id; }},on:{"input":_vm.filterDistricts},model:{value:(_vm.province_id),callback:function ($$v) {_vm.province_id=$$v},expression:"province_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "กรุณาเลือกจังหวัด" : ""))])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('label',[_vm._v("เขต/อำเภอ")]),_c('validation-provider',{attrs:{"name":"district_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"district_name","options":_vm.district_Info,"reduce":function (name) { return name.district_id; }},on:{"input":_vm.filterSubDistricts},model:{value:(_vm.district_id),callback:function ($$v) {_vm.district_id=$$v},expression:"district_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "กรุณาเลือกเขต/อำเภอ" : ""))])]}}])})],1)]),_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-md-6"},[_c('label',[_vm._v("แขวง/ตำบล")]),_c('validation-provider',{attrs:{"name":"subdistrict_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"sub_district_name","options":_vm.subdistrict_Info,"reduce":function (name) { return name.tumbon_id; }},on:{"input":_vm.getZipcode},model:{value:(_vm.subdistrict_id),callback:function ($$v) {_vm.subdistrict_id=$$v},expression:"subdistrict_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "กรุณาเลือกแขวง/ตำบล" : ""))])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('label',{attrs:{"for":"postalCode"}},[_vm._v("รหัสไปรษณีย์")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.zipcode),expression:"zipcode"}],staticClass:"form-control",attrs:{"id":"postalCode","type":"text","readonly":""},domProps:{"value":(_vm.zipcode)},on:{"input":function($event){if($event.target.composing){ return; }_vm.zipcode=$event.target.value}}})])])])])]),_c('div',{staticClass:"col-lg-6",staticStyle:{"z-index":"0"}},[_c('div',{staticClass:"card hovered"},[_c('h3',[_vm._v("ข้อมูลมิจฉาชีพ")]),_c('form',[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"infoType"}},[_vm._v("ประเภทข้อมูล")]),_c('validation-provider',{attrs:{"name":"channel_type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"text","options":_vm.channel_list,"reduce":function (ele) { return ele.value; }},on:{"input":function($event){(_vm.phone_number = null),
                      (_vm.line_id = null),
                      (_vm.link_facebook = null),
                      (_vm.tiktok_id = null),
                      (_vm.sms = null),
                      (_vm.bank_code = null),
                      (_vm.bank_account = null),
                      (_vm.bank_account_name = null)}},model:{value:(_vm.channel_type),callback:function ($$v) {_vm.channel_type=$$v},expression:"channel_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "กรุณาเลือกประเภทข้อมูล" : ""))])]}}])})],1),(_vm.channel_type === 1)?_c('div',{staticClass:"form-group"},[_c('validation-provider',{attrs:{"name":"phoneNumber","rules":"required|length:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{attrs:{"for":"phoneNumber"}},[_vm._v("หมายเลขโทรศัพท์")]),_c('b-form-input',{staticClass:"form-control",attrs:{"id":"phoneNumber","type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.phone_number),callback:function ($$v) {_vm.phone_number=$$v},expression:"phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "กรุณากรอกหมายเลขโทรศัพท์ 10 หลัก" : ""))])]}}],null,false,2568866901)})],1):_vm._e(),(_vm.channel_type === 2)?_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"lineId"}},[_vm._v("ไอดีไลน์")]),_c('validation-provider',{attrs:{"name":"lineId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"id":"lineId","type":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.line_id),callback:function ($$v) {_vm.line_id=$$v},expression:"line_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "กรุณากรอกไอดีไลน์" : ""))])]}}],null,false,1643551637)})],1):_vm._e(),(_vm.channel_type === 3)?_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"facebook"}},[_vm._v("กรุณาระบุ ลิงก์เฟซบุ๊ก")]),_c('validation-provider',{attrs:{"name":"link_facebook","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"id":"facebook","type":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.link_facebook),callback:function ($$v) {_vm.link_facebook=$$v},expression:"link_facebook"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "กรุณากรอกข้อมูล ลิงก์เฟซบุ๊ก" : ""))])]}}],null,false,4100099284)})],1):_vm._e(),(_vm.channel_type === 4)?_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"bankName"}},[_vm._v("ชื่อธนาคาร")]),_c('validation-provider',{attrs:{"name":"bank_code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.bankname,"reduce":function (name) { return name.bankcode; }},model:{value:(_vm.bank_code),callback:function ($$v) {_vm.bank_code=$$v},expression:"bank_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "กรุณาเลือกธนาคาร" : ""))])]}}],null,false,1871241520)}),_c('label',{attrs:{"for":"accountNumber"}},[_vm._v("หมายเลขบัญชี")]),_c('validation-provider',{attrs:{"name":"accountNumber","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"id":"accountNumber","type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.bank_account),callback:function ($$v) {_vm.bank_account=$$v},expression:"bank_account"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "กรุณากรอกหมายเลขบัญชี" : ""))])]}}],null,false,2295408758)}),_c('label',{attrs:{"for":"accountName"}},[_vm._v("ชื่อบัญชี")]),_c('validation-provider',{attrs:{"name":"bank_account_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"id":"accountName","type":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.bank_account_name),callback:function ($$v) {_vm.bank_account_name=$$v},expression:"bank_account_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "กรุณากรอกชื่อบัญชี" : ""))])]}}],null,false,720381553)})],1):_vm._e(),(_vm.channel_type === 5)?_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"TikTok"}},[_vm._v("TikTok")]),_c('validation-provider',{attrs:{"name":"tiktok_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"id":"TikTok","type":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.tiktok_id),callback:function ($$v) {_vm.tiktok_id=$$v},expression:"tiktok_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "กรุณากรอกข้อมูล TikTok" : ""))])]}}],null,false,877748953)})],1):_vm._e(),(_vm.channel_type === 6)?_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"sms"}},[_vm._v("SMS")]),_c('validation-provider',{attrs:{"name":"sms","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"id":"sms","type":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.sms),callback:function ($$v) {_vm.sms=$$v},expression:"sms"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "กรุณากรอกข้อมูล SMS" : ""))])]}}],null,false,1569793600)})],1):_vm._e(),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"description"}},[_vm._v("รายละเอียด")]),_c('validation-provider',{attrs:{"name":"detail","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.detail),expression:"detail"}],staticClass:"form-control",attrs:{"id":"description"},domProps:{"value":(_vm.detail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.detail=$event.target.value}}}),_vm._v(" "),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "กรุณากรอกรายละเอียด" : ""))])]}}])})],1)])])])])]),_c('div',{staticClass:"button-container"},[_c('button',{staticClass:"button-edit",on:{"click":_vm.submitData}},[_vm._v("บันทึกข้อมูล")])]),_c('transition',{attrs:{"name":"fade"}},[(_vm.showModal)?_c('div',{staticClass:"modal"},[_c('div',{class:['modal-content', _vm.modalClass]},[_c('span',{staticClass:"close",on:{"click":_vm.closeModal}},[_vm._v("×")]),_c('p',[_vm._v(_vm._s(_vm.modalMessage))])])]):_vm._e()])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo-container row"},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":"\\images\\CYBERCHECK.png","alt":"CYBERCHECK"}})]),_c('div',{staticClass:"text-transition"},[_c('p',[_vm._v("แบบฟอร์มแจ้งข้อมูลมิจฉาชีพ")])])])}]

export { render, staticRenderFns }
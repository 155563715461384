export default [
  // Dashboard and Member
  {
    header: "Dashboard and Number",
    resource: "User",
  },
  {
    title: "แดชบอร์ด",
    route: "dashboard",
    icon: "far fa-chart-line",
    resource: "User",
  },
  {
    title: "ข้อมูลเบอร์โทรศัพท์",
    route: "member",
    icon: "far fa-phone-alt",
    resource: "User",
  },
  {
    title: "ข้อมูลเลขบัญชี",
    route: "bankaccount",
    icon: "fas fa-id-card",
    resource: "User",
  },
  {
    title: "ข้อมูล SMS",
    route: "sms-list",
    icon: "far fa-sms",
    resource: "User",
  },
  {
    title: "Detect",
    route: "detect",
    icon: "far fa-server",
    resource: "User",
  },
  {
    title: "ข่าวสารประชาสัมพันธ์",
    route: "news",
    icon: "far fa-newspaper",
    resource: "User",
  },
  {
    title: "ข้อมูล TPO",
    route: "tpo-list",
    icon: "fas fa-info-circle",
    resource: "User",
  },
  {
    title: "Report from line@",
    route: "line-list",
    icon: "fab fa-line",
    resource: "User",
  },
];

<template>
  <div class="container">
    <div class="logo-container row">
      <div class="logo">
        <img src="\images\CYBERCHECK.png" alt="CYBERCHECK" />
      </div>
      <div class="text-transition">
        <p>แบบฟอร์มแจ้งข้อมูลมิจฉาชีพ</p>
      </div>
    </div>
    <validation-observer ref="formRules">
      <div class="row custom-card">
        <div class="col-lg-6" style="z-index: 1">
          <div class="card hovered">
            <h3>ข้อมูลผู้แจ้ง</h3>
            <form>
              <div class="form-group row">
                <div class="col-md-6">
                  <validation-provider
                    #default="{ errors }"
                    name="firstName"
                    rules="required"
                  >
                    <label for="firstName">ชื่อ</label>
                    <b-form-input
                      id="firstName"
                      v-model="first_name"
                      type="text"
                      class="form-control"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{
                      errors[0] ? "กรุณากรอกชื่อ" : ""
                    }}</small>
                  </validation-provider>
                </div>
                <div class="col-md-6">
                  <validation-provider
                    #default="{ errors }"
                    name="lastName"
                    rules="required"
                  >
                    <label for="lastName">นามสกุล</label>
                    <b-form-input
                      id="lastName"
                      v-model="last_name"
                      type="text"
                      class="form-control"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{
                      errors[0] ? "กรุณากรอกนามสกุล" : ""
                    }}</small>
                  </validation-provider>
                </div>
              </div>
              <!-- <div class="form-group">
                <label for="address">ที่อยู่</label>
                <validation-provider
                  #default="{ errors }"
                  name="address"
                  rules="required"
                >
                  <b-form-input
                    id="address"
                    v-model="address"
                    type="text"
                    class="form-control"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] ? 'กรุณากรอกที่อยู่' : '' }}</small>
                </validation-provider>

              </div> -->
              <div class="form-group row">
                <div class="col-md-6">
                  <label>จังหวัด</label>
                  <validation-provider
                    #default="{ errors }"
                    name="province_id"
                    rules="required"
                  >
                    <v-select
                      v-model="province_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="province_name"
                      :options="province_Info"
                      :reduce="(name) => name.province_id"
                      @input="filterDistricts"
                    />
                    <small class="text-danger">{{
                      errors[0] ? "กรุณาเลือกจังหวัด" : ""
                    }}</small>
                  </validation-provider>
                </div>

                <div class="col-md-6">
                  <label>เขต/อำเภอ</label>
                  <validation-provider
                    #default="{ errors }"
                    name="district_id"
                    rules="required"
                  >
                    <v-select
                      v-model="district_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="district_name"
                      :options="district_Info"
                      :reduce="(name) => name.district_id"
                      @input="filterSubDistricts"
                    />
                    <small class="text-danger">{{
                      errors[0] ? "กรุณาเลือกเขต/อำเภอ" : ""
                    }}</small>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6">
                  <label>แขวง/ตำบล</label>
                  <validation-provider
                    #default="{ errors }"
                    name="subdistrict_id"
                    rules="required"
                  >
                    <v-select
                      v-model="subdistrict_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="sub_district_name"
                      :options="subdistrict_Info"
                      :reduce="(name) => name.tumbon_id"
                      @input="getZipcode"
                    />
                    <small class="text-danger">{{
                      errors[0] ? "กรุณาเลือกแขวง/ตำบล" : ""
                    }}</small>
                  </validation-provider>
                </div>
                <div class="col-md-6">
                  <label for="postalCode">รหัสไปรษณีย์</label>
                  <input
                    id="postalCode"
                    v-model="zipcode"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="col-lg-6" style="z-index: 0">
          <div class="card hovered">
            <h3>ข้อมูลมิจฉาชีพ</h3>
            <form>
              <div class="form-group">
                <label for="infoType">ประเภทข้อมูล</label>
                <validation-provider
                  #default="{ errors }"
                  name="channel_type"
                  rules="required"
                >
                  <v-select
                    v-model="channel_type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="text"
                    :options="channel_list"
                    :reduce="(ele) => ele.value"
                    @input="
                      (phone_number = null),
                        (line_id = null),
                        (link_facebook = null),
                        (tiktok_id = null),
                        (sms = null),
                        (bank_code = null),
                        (bank_account = null),
                        (bank_account_name = null)
                    "
                  />
                  <small class="text-danger">{{
                    errors[0] ? "กรุณาเลือกประเภทข้อมูล" : ""
                  }}</small>
                </validation-provider>
              </div>

              <div v-if="channel_type === 1" class="form-group">
                <validation-provider
                  #default="{ errors }"
                  name="phoneNumber"
                  rules="required|length:10"
                >
                  <label for="phoneNumber">หมายเลขโทรศัพท์</label>
                  <b-form-input
                    id="phoneNumber"
                    v-model="phone_number"
                    type="number"
                    class="form-control"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? "กรุณากรอกหมายเลขโทรศัพท์ 10 หลัก" : ""
                  }}</small>
                </validation-provider>
              </div>
              <div v-if="channel_type === 2" class="form-group">
                <label for="lineId">ไอดีไลน์</label>
                <validation-provider
                  #default="{ errors }"
                  name="lineId"
                  rules="required"
                >
                  <b-form-input
                    id="lineId"
                    v-model="line_id"
                    type="text"
                    class="form-control"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? "กรุณากรอกไอดีไลน์" : ""
                  }}</small>
                </validation-provider>
              </div>
              <div v-if="channel_type === 3" class="form-group">
                <label for="facebook">กรุณาระบุ ลิงก์เฟซบุ๊ก</label>
                <validation-provider
                  #default="{ errors }"
                  name="link_facebook"
                  rules="required"
                >
                  <b-form-input
                    id="facebook"
                    v-model="link_facebook"
                    type="text"
                    class="form-control"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? "กรุณากรอกข้อมูล ลิงก์เฟซบุ๊ก" : ""
                  }}</small>
                </validation-provider>
              </div>

              <div v-if="channel_type === 4" class="form-group">
                
                <label for="bankName">ชื่อธนาคาร</label>
                <validation-provider
                  #default="{ errors }"
                  name="bank_code"
                  rules="required"
                >
                  <v-select
                    v-model="bank_code"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="bankname"
                    :reduce="(name) => name.bankcode"
                  />
                  <small class="text-danger">{{
                    errors[0] ? "กรุณาเลือกธนาคาร" : ""
                  }}</small>
                </validation-provider>

                <label for="accountNumber">หมายเลขบัญชี</label>
                <validation-provider
                  #default="{ errors }"
                  name="accountNumber"
                  rules="required"
                >
                  <b-form-input
                    id="accountNumber"
                    v-model="bank_account"
                    type="number"
                    class="form-control"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? "กรุณากรอกหมายเลขบัญชี" : ""
                  }}</small>
                </validation-provider>

                <label for="accountName">ชื่อบัญชี</label>
                <validation-provider
                  #default="{ errors }"
                  name="bank_account_name"
                  rules="required"
                >
                  <b-form-input
                    id="accountName"
                    v-model="bank_account_name"
                    type="text"
                    class="form-control"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? "กรุณากรอกชื่อบัญชี" : ""
                  }}</small>
                </validation-provider>
              </div>

              <div v-if="channel_type === 5" class="form-group">
                <label for="TikTok">TikTok</label>
                <validation-provider
                  #default="{ errors }"
                  name="tiktok_id"
                  rules="required"
                >
                  <b-form-input
                    id="TikTok"
                    v-model="tiktok_id"
                    type="text"
                    class="form-control"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? "กรุณากรอกข้อมูล TikTok" : ""
                  }}</small>
                </validation-provider>
              </div>

              <div v-if="channel_type === 6" class="form-group">
                <label for="sms">SMS</label>
                <validation-provider
                  #default="{ errors }"
                  name="sms"
                  rules="required"
                >
                  <b-form-input
                    id="sms"
                    v-model="sms"
                    type="text"
                    class="form-control"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? "กรุณากรอกข้อมูล SMS" : ""
                  }}</small>
                </validation-provider>
              </div>

              <div class="form-group">
                <label for="description">รายละเอียด</label>
                <validation-provider
                  #default="{ errors }"
                  name="detail"
                  rules="required"
                >
                  <textarea
                    id="description"
                    v-model="detail"
                    class="form-control"
                  />
                  <small class="text-danger">{{
                    errors[0] ? "กรุณากรอกรายละเอียด" : ""
                  }}</small>
                </validation-provider>
              </div>
            </form>
          </div>
        </div>
      </div>
    </validation-observer>
    <div class="button-container">
      <button class="button-edit" @click="submitData">บันทึกข้อมูล</button>
    </div>
    <!-- Modal -->
    <transition name="fade">
      <div v-if="showModal" class="modal">
        <div :class="['modal-content', modalClass]">
          <span class="close" @click="closeModal">&times;</span>
          <p>{{ modalMessage }}</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { BFormInput } from "bootstrap-vue";
import liff from "@line/liff";

export default {
  components: {
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      required,
      channel_list: [
        { text: "เบอร์โทรศัพท์", value: 1 },
        { text: "ไอดีไลน์", value: 2 },
        { text: "Facebook", value: 3 },
        { text: "บัญชีธนาคาร", value: 4 },
        { text: "TikTok", value: 5 },
        { text: "SMS", value: 6 },
      ],
      province_Info: [],
      district_Info: [],
      districtALL: [],
      subdistrict_Info: [],
      subdistrictALL: [],
      bankname: [],

      first_name: null,
      last_name: null,
      address: null,
      province_id: null,
      district_id: null,
      subdistrict_id: null,
      zipcode: null,

      channel_type: 1,
      phone_number: null,
      line_id: null,
      link_facebook: null,
      tiktok_id: null,
      sms:null,
      bank_code: null,
      bank_account: null,
      bank_account_name: null,
      detail: null,

      userId: null,
      displayName: null,
      pictureUrl: null,

      showModal: false,
      modalMessage: "",
      modalClass: "",
    };
  },
  mounted() {
    this.getProvinces();
    this.getDistrict();
    this.getSubdistrict();
    this.getBank();
    this.liff();
  },
  methods: {
    async liff() {
      liff.init(
        { liffId: "2006476017-VNzRKDd0" },
        () => {
          if (liff.isLoggedIn()) {
            this.getProfile();
          } else {
            liff.login();
          }
        },
        (err) => console.error(err.code, err.message)
      );
    },
    async getProfile() {
      liff
        .getProfile()
        .then((profile) => {
          //   console.log(profile)
          this.userId = profile.userId;
          this.displayName = profile.displayName;
          this.pictureUrl = profile.pictureUrl;
          // userId.value = profile.userId

          // document.getElementById("pictureUrl").src = profile.pictureUrl;
          // document.getElementById("userId").innerHTML = '<b>UserId:</b> ' + profile.userId;
          // document.getElementById("displayName").innerHTML = '<b>DisplayName:</b> ' + profile.displayName;
          // document.getElementById("statusMessage").innerHTML = '<b>StatusMessage:</b> ' + profile.statusMessage;
          // document.getElementById("getDecodedIDToken").innerHTML = '<b>Email:</b> ' + liff.getDecodedIDToken().email;
        })
        .catch((err) => console.error(err));
    },
    async getProvinces() {
      try {
        const response = await this.$http.get("/get/province");
        this.province_Info = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getDistrict() {
      try {
        const response = await this.$http.get("/get/district");
        this.district_Info = response.data;
        this.districtALL = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getSubdistrict() {
      try {
        const response = await this.$http.get("/get/subdistrict");
        this.subdistrict_Info = response.data;
        this.subdistrictALL = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getZipcode() {
      try {
        const params = {
          tumbon_id: this.subdistrict_id,
        };
        const response = await this.$http.get("/get/zipcode", { params });
        this.zipcode = response.data[0].zipcode;
        // console.log(response.data[0].zipcode)
      } catch (error) {
        console.log(error);
      }
    },
    async getBank() {
      try {
        const response = await this.$http.get("/get/bank");
        this.bankname = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async submitData() {
      this.$refs.formRules.validate().then(async (success) => {
        if (success) {
          const channel = this.channel_list.find(
            (ele) => ele.value === this.channel_type
          );
          const province = this.province_Info.find(
            (ele) => ele.province_id === this.province_id
          );
          const district = this.district_Info.find(
            (ele) => ele.district_id === this.district_id
          );
          const subdistrict = this.subdistrict_Info.find(
            (ele) => ele.tumbon_id === this.subdistrict_id
          );
          const payload = {
            userId: this.userId,
            displayName: this.displayName,
            pictureUrl: this.pictureUrl,
            first_name: this.first_name,
            last_name: this.last_name,
            address: this.address,
            province_id: this.province_id,
            province_name: province.province_name,
            district_id: this.district_id,
            district_name: district.district_name,
            subdistrict_id: this.subdistrict_id,
            subdistrict_name: subdistrict.sub_district_name,
            zipcode: this.zipcode,
            channel_type: this.channel_type,
            channel_name: channel.text,
            phone_number: this.phone_number,
            line_id: this.line_id,
            link_facebook: this.link_facebook,
            tiktok_id: this.tiktok_id,
            sms:this.sms,
            bank_code: this.bank_code,
            bank_name: null,
            bank_account: this.bank_account,
            bank_account_name: this.bank_account_name,
            detail: this.detail,
          };
          if (this.channel_type === 4) {
            const bank = this.bankname.find(
              (ele) => ele.bankcode === this.bank_code
            );
            payload.bank_name = bank.name;
          }

          //   console.log(payload)
          try {
            const { data: res } = await this.$http.post(
              "/infoLine/create",
              payload
            );
            if (res.status) {
              this.$swal({
                icon: "success",
                title: '<h3 style="color: #141414">บันทึกข้อมูลเรียบร้อย</h3>',
                text: "",
                confirmButtonText: "ปิด",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.ResetForm();
            } else {
              this.$swal({
                icon: "error",
                title:
                  '<h3 style="color: #141414">เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง</h3>',
                text: "",
                confirmButtonText: "ปิด",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            }
          } catch (error) {
            this.$swal({
              icon: "error",
              title:
                '<h3 style="color: #141414">เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง</h3>',
              text: "",
              confirmButtonText: "ปิด",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          }
        }
      });
      //   else {
      //     this.modalMessage = 'กรุณากรอกข้อมูลให้ครบถ้วน'
      //     this.modalClass = 'warning' // set class for warning
      //   }

      //   this.showModal = true // Show the modal
    },
    filterDistricts() {
      if (this.province_id) {
        this.district_Info = this.districtALL.filter(
          (district) => district.province_id === this.province_id
        );
        this.subdistrict_Info = this.subdistrictALL.filter(
          (subdistrict) => subdistrict.changwat_id === this.province_id
        );
        this.district_id = null;
        this.subdistrict_id = null;
        this.zipcode = null;
      }
    },
    filterSubDistricts() {
      if (this.district_id) {
        this.subdistrict_Info = this.subdistrictALL.filter(
          (subdistrict) => subdistrict.ampur_id === this.district_id
        );
        this.subdistrict_id = null;
        this.zipcode = null;
      }
    },
    closeModal() {
      this.showModal = false;
    },
    ResetForm() {
      this.first_name = null;
      this.last_name = null;
      this.address = null;
      this.province_id = null;
      this.district_id = null;
      this.subdistrict_id = null;
      this.zipcode = null;
      this.channel_type = 1;
      this.phone_number = null;
      this.line_id = null;
      this.link_facebook = null;
      this.tiktok_id = null;
      this.sms = null;
      this.bank_code = null;
      this.bank_account = null;
      this.bank_account_name = null;
      this.detail = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Athiti:wght@200;300;400;500;600;700&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap');

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  max-width: 100%;
  margin: auto;
  font-family: 'Athiti', sans-serif;
  min-height: 100vh;
  justify-content: center;
  background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(22, 95, 231, 0.3) 0%, rgb(206, 232, 236) 100%);
  /* align-items: center; */
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  width: auto;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.success {
  background-color: #3c763d;
  color: #ffffff;
  font-weight: 500;
}

.error {
  background-color: #a94442;
  color: #ffffff;
  font-weight: 500;
}

.warning {
  background-color: #33448f;
  color: #ffffff;
  font-weight: 500;
}

.close {
  cursor: pointer;
  float: right;
  font-size: 20px;
}

.custom-card {
  margin-left: 20%;
  margin-right: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  background: rgb(239, 245, 248), 242, 248);
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(17, 35, 199, 0.1);
  padding: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.card.hovered {
  box-shadow: 0 8px 30px rgba(22, 95, 231, 0.3);
  transition: transform 0.3s, box-shadow 0.3s;
}

.card.hovered:hover {
  transform: scale(1.01);
  box-shadow: 0 8px 30px rgba(22, 95, 231, 0.3);
}

h3 {
  margin-bottom: 15px;
  color: #112e6e;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(18, 115, 145, 0.3);
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: -15px;
}

.button-edit {
  width: 300px;
  padding: 10px 20px;
  background: linear-gradient(to right, #0f3381, #176cbd);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  transition: transform 0.3s, box-shadow 0.3s;
  font-weight: 500;
}

.button-edit:hover {
  background-color: #176cbd;
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(22, 95, 231, 0.3);
}

.logo {
  text-align: center;
  margin-bottom: 20px;
}

.logo img {
  width: 150px;
  border-radius: 20px;
  transition: transform 0.3s, filter 0.3s;
  box-shadow: 0 8px 30px rgba(22, 95, 231, 0.3);
}

.logo img:hover {
  transform: scale(1.02);
  filter: brightness(1.1);
}

label {
  color: rgb(29, 29, 29);
  font-size: 13px;
  font-weight: 500;
}

.form-control {
  color: rgb(29, 29, 29);
  font-size: 13px;
  font-weight: 500;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-transition {
  text-shadow: 2px 2px 5px rgba(18, 115, 145, 0.3);
  color: #112e6e;
  padding-left: 50px;
  font-size: 50px;
  font-weight: 500;
  opacity: 0;
  transform: translateX(20px);
  animation: fadeIn 0.5s forwards;
  animation-delay: s;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (max-width: 800px) {
  .text-transition p {
    font-size: 25px;
    padding-right: 40px;
  }

  .custom-card {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
